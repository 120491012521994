import { defineStore } from 'pinia'
import type { HeadTagInterface } from '@voix/types'

interface HeadTagsStoreInterface {
  headTags: Array<HeadTagInterface>
  headTag: HeadTagInterface | object
}
export const useHeadTagStore = defineStore('headTagStore', {
  state: () => ({
    headTags: [],
    headTag: {},
  } as HeadTagsStoreInterface),

  actions: {
    setHeadTags(headTags: Array<HeadTagInterface>) {
      this.headTags = headTags
    },
    setHeadTag(headTag: object) {
      this.headTag = headTag
    },
  },
})
