import { useHeadTagStore } from '@voix/store/headTagStore'
import { useAdminStore } from '@voix/store/adminStore'
import { useVoixToast } from '@voix/composables/useVoixToast'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'

interface HeadTagsArgs {
  filter: string
  orderBy: Array<object | string>
  first: number
  page: number
}

function setHeadTagsInStore(response: any) {
  if (response) {
    const headTagStore = useHeadTagStore()
    headTagStore.setHeadTags(response)
  }
}

async function getHeadTags(variables: HeadTagsArgs) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  variables.orderBy = variables.orderBy.map((orderBy: object | string) => {
    return `${orderBy.column}:${orderBy.order}`
  })
  const query = new URLSearchParams(variables).toString()
  const request = await $voixNuxtApi(
    `/api/voix/head-tags?${query.toString()}`,
  )

  setHeadTagsInStore(request.data.value)

  adminStore.isLoading = false

  return request
}

interface HeadTagArgs {
  id: string
}

function setHeadTagInStore(response: any) {
  if (response) {
    const headTagStore = useHeadTagStore()
    headTagStore.setHeadTag(response)
  }
}

async function getHeadTag(variables: HeadTagArgs) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/head-tags/${variables.id}`,
  )

  setHeadTagInStore(request.data.value)

  adminStore.isLoading = false

  return request
}

interface HeadTagCreateArgs {
  type: string
  content: string
  position: string
  is_partytown: boolean
}

async function createHeadTag(variables: HeadTagCreateArgs) {
  const adminStore = useAdminStore()
  const voixToast = useVoixToast()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/head-tags`,
    {
      method: 'POST',
      body: Object.assign({ page_id: 0 }, variables),
    },
  )

  adminStore.isLoading = false

  if (request.data.value) {
    voixToast.add(`Head Tag Created: ${variables.type}`, 'msg')

    return request
  }
  else {
    throw new Error('Unable to save')
  }
}

interface HeadTagUpdateArgs {
  id: string
  type: string
  content: string
  position: string
  is_partytown: boolean
}

async function updateHeadTag(variables: HeadTagUpdateArgs) {
  const adminStore = useAdminStore()
  const voixToast = useVoixToast()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/head-tags/${variables.id}`,
    {
      method: 'PUT',
      body: Object.assign({ page_id: 0 }, variables),
    },
  )

  adminStore.isLoading = false

  if (request.data.value) {
    voixToast.add(`Head Tag Updated: ${variables.type}`, 'msg')

    return request
  }
  else {
    throw new Error('Unable to save')
  }
}

async function deleteHeadTag(variables: { id: string }) {
  const adminStore = useAdminStore()
  const voixToast = useVoixToast()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/head-tags/${variables.id}`,
    {
      method: 'DELETE',
    },
  )

  adminStore.isLoading = false

  if (request.data.value) {
    voixToast.add(`Head Tag Deleted`, 'msg')

    return request
  }
  else {
    throw new Error('Unable to save')
  }
}

export { getHeadTags, getHeadTag, createHeadTag, updateHeadTag, deleteHeadTag }
